import img1 from '../images/team1.jpg';
import img2 from '../images/team2.jpg';
import img3 from '../images/team3.jpg';
import img4 from '../images/team4.jpg';
import img5 from '../images/team5.jpg';
import img6 from '../images/team6.jpg';
import img7 from '../images/team7.jpg';

const teamData = [
  {
    id: 1,
    img: img1,
    name: 'Najmiddin',
    surname: 'Mukhitdinov',
    role: 'Founder',
    telegram: '@nmukhitdinov',
  },
  {
    id: 12,
    img: img7,
    name: 'Diyorbek',
    surname: 'Nomozov',
    role: 'Head of organization',
    telegram: '@diyorbeknomozovv',
  },
  {
    id: 10,
    img: img6,
    name: 'Ozoda',
    surname: 'Muminova',
    role: 'Media Partner',
    telegram: '@ozodakhonm',
  },
  {
    id: 3,
    img: img2,
    name: 'Fozilxon',
    surname: "Buzrukxo'jayev",
    role: 'Web Developer',
    telegram: '@fozilkhon_0601',
  },
  {
    id: 2,
    img: img4,
    name: 'Abror',
    surname: 'Nematov',
    role: 'Content manager',
    telegram: '@nematovabrorbek',
  },

  {
    id: 4,
    img: img3,
    name: 'Shohjahon',
    surname: 'Ramazonov',
    role: 'Social Media Manager',
    telegram: '@shohjahon_ramazonov',
  },
  {
    id: 5,
    img: img5,
    name: 'Otabek',
    surname: 'Khalilov',
    role: 'Designer',
    telegram: '@khalilov1701',
  },
];

export default teamData;
