const faqData = [
  {
    id: 1,
    question: 'TeenHack 2024 hakatoni nima?',
    answer:
      "TeenHack 2024 hakatoni, Target International School va Zamon AI tomonidan tashkil etilgan, maktab o'quvchilari uchun maxsus bir hackathon.",
  },
  {
    id: 2,
    question: 'Registratsiya qanday qilib amalga oshiriladi?',
    answer:
      "Registratsiya 25-Iyul sanasiga qadar amalga oshirilishi kerak va bu 23:59 gacha davom etadi. Shu link orqali ro'yxatdan o'tiladi ",
    link: 'https://docs.google.com/forms/d/1T6Ef1fnxQU72cpc0rozwlh4SWrpenu7G0Fe8LHohTqY/edit',
  },
  {
    id: 3,
    question: "Sovg'alar qanday eslatib o'tiladi?",
    answer:
      "TeenHack hakatonining sovg'alari 1-O'rin uchun 5 million so'm, 2-O'rin uchun 3 million so'm va 3-O'rin uchun 2 million so'm mablag'larini o'z ichiga oladi.",
  },
  {
    id: 4,
    question: "Qanday qilib hakaton o'tkaziladi?",
    answer:
      "TeenHack hakatoni 27-28-29-Iyul kunlari o'tkaziladi. O'quvchilar hackathon davomida yangi yondoshlar o'rganish, muammolar yechish va ijro etilishi lozim bo'lgan vazifalarga yechim topishlari talab qilinadi.",
  },
  {
    id: 5,
    question: 'Kim qatnashishi mumkin?',
    answer:
      "TeenHack Hakatoniga faqatgina maktab o'quvchilari qatnashishi mumkin",
  },
  {
    id: 6,
    question: "Qanday qilib TeenHack hakatoni yutuqlarini e'lon qilinadi?",
    answer:
      "Yutuqlar TeenHack hakatoni saytida va ijtimoiy tarmoqlarda e'lon qilinadi, bu yerda qanday qilib g'oliblar e'lon qilinayotganligi, shu jumladan, ularning nomi, loyiha haqida ma'lumotlar keltiriladi.",
  },
];

export default faqData;
